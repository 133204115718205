import { Selectable } from '@hakimo-ui/shared/ui-base';
import {
  CameraFilterValue,
  EmployeeFilterValue,
  LocationFilterValue,
  SourceEntityNameFilterValue,
  TenantFilterValue,
} from '../common';
import { EscalationStatus } from '../escalations';
import { LocationAlarmStatus } from '../location-alarms';
import { AlarmStatus } from './alarms';

export type StatusFilterValue<
  T extends AlarmStatus | LocationAlarmStatus | EscalationStatus
> = {
  values: T[];
  negative: boolean;
};

export type AlarmTypeFilterValue = { values: Selectable[]; negative: boolean };

export interface TimeFilterValue {
  period: TimePeriod;
  customRangeStart: string; // `YYYY-MM-DDThh:mm`
  customRangeEnd: string; // `YYYY-MM-DDThh:mm`
}

export type SourceSystemFilterValue = Selectable[];

export type FilterType =
  | 'status'
  | 'time'
  | 'sourceSystem'
  | 'location'
  | 'type'
  | 'sourceEntity'
  | 'employee'
  | 'tenant'
  | 'camera';

interface BaseFilterConfig {
  type: FilterType;
  label?: string;
}

export interface StatusFilterOption<
  T extends AlarmStatus | LocationAlarmStatus | EscalationStatus
> {
  id: string;
  name: T;
}

interface StatusFilterConfig<T extends AlarmStatus | LocationAlarmStatus>
  extends BaseFilterConfig {
  type: 'status';
  options: StatusFilterOption<T>[];
  defaultValue: StatusFilterValue<T>;
}

interface TimeFilterConfig extends BaseFilterConfig {
  type: 'time';
  defaultValue: TimeFilterValue;
}

interface SourceSystemFilterConfig extends BaseFilterConfig {
  type: 'sourceSystem';
  defaultValue: SourceSystemFilterValue;
}

interface LocationFilterConfig extends BaseFilterConfig {
  type: 'location';
  defaultValue: LocationFilterValue;
}

interface TypeFilterConfig extends BaseFilterConfig {
  type: 'type';
  defaultValue: AlarmTypeFilterValue;
}

interface SourceEntityFilterConfig extends BaseFilterConfig {
  type: 'sourceEntity';
  defaultValue: SourceEntityNameFilterValue;
}

interface EmployeeFilterConfig extends BaseFilterConfig {
  type: 'employee';
  defaultValue: EmployeeFilterValue;
}

interface TenantFilterConfig extends BaseFilterConfig {
  type: 'tenant';
  defaultValue: TenantFilterValue;
}
interface CameraFilterConfig extends BaseFilterConfig {
  type: 'camera';
  defaultValue: CameraFilterValue;
}

export type FilterConfig =
  | StatusFilterConfig<AlarmStatus | LocationAlarmStatus | EscalationStatus>
  | TimeFilterConfig
  | SourceSystemFilterConfig
  | LocationFilterConfig
  | TypeFilterConfig
  | SourceEntityFilterConfig
  | EmployeeFilterConfig
  | TenantFilterConfig
  | CameraFilterConfig;

export interface FilterValues {
  status:
    | StatusFilterValue<AlarmStatus>
    | StatusFilterValue<LocationAlarmStatus>;
  time: TimeFilterValue;
  sourceSystem: SourceSystemFilterValue;
  location: LocationFilterValue;
  type: AlarmTypeFilterValue;
  sourceEntity: SourceEntityNameFilterValue;
  employee: EmployeeFilterValue;
  tenant: TenantFilterValue;
  camera: CameraFilterValue; //FilterValues added for Camera
}

export interface Option<T extends string = string> {
  id: string;
  name: T;
}

export enum TimePeriod {
  ALL_TIME = 'All Time',
  PAST_MINUTE = 'Past Minute',
  PAST_HOUR = 'Past Hour',
  PAST_SIX_HOURS = 'Past 6 Hours',
  PAST_24_HOURS = 'Past 24 Hours',
  PAST_WEEK = 'Past Week',
  PAST_MONTH = 'Past Month',
  PAST_THREE_MONTHS = 'Past 3 Months',
  CUSTOM_RANGE = 'Custom Range',
}

export interface MonitoringFilterValues extends Omit<FilterValues, 'time'> {
  status: StatusFilterValue<AlarmStatus>;
}
