/* eslint-disable max-lines */
import { useSOP, useUpdateScanEscalation } from '@hakimo-ui/hakimo/data-access';
import { AudioRecorder } from '@hakimo-ui/hakimo/feature-shared';
import {
  ScanTenantSOP,
  TwilioLogType,
  UpdateEscalationRequest,
} from '@hakimo-ui/hakimo/types';
import { LoadingIndicator } from '@hakimo-ui/hakimo/ui-elements';
import { toast, trackEvent } from '@hakimo-ui/hakimo/util';
import { Alert, Button } from '@hakimo-ui/shared/ui-base';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { DispatchType } from '../../types/sop';
import { ResolveAction } from '../common/ResolveAction';
import DispatchView from './DispatchView';
import SOPInfo from './SOPInfo';

interface Props {
  onResolveEscalation: () => void;
  escalationId: string;
  locationId: string;
  escalatedCamId: string;
  onCancel: () => void;
}

export function ScanSOP(props: Props) {
  const {
    onResolveEscalation,
    escalationId,
    locationId,
    onCancel,
    escalatedCamId,
  } = props;
  const [scanSOP, setScanSOP] = useState<ScanTenantSOP | undefined>(undefined);
  const [updateType, setUpdateType] = useState('StatusUpdate');

  const {
    data: sopData,
    isLoading: isSOPLoading,
    isError: isLoadSOPError,
    error: loadSOPError,
  } = useSOP({ locationId });

  const [dispatchType, setDispatchType] = useState<DispatchType | undefined>(
    undefined
  );

  const { isSuccess, mutate, isError, error, isLoading } =
    useUpdateScanEscalation(escalationId);

  useEffect(() => {
    if (isSuccess && updateType === 'StatusUpdate') {
      toast('Escalation resolved successfully');
      onResolveEscalation();
    }
  }, [isSuccess, onResolveEscalation, updateType]);

  useEffect(() => {
    if (sopData && sopData.scan_sop) {
      setScanSOP(sopData.scan_sop);
    }
  }, [sopData]);

  const onDispatchPD = () => setDispatchType(DispatchType.PD);
  const onDispatchGuard = () => setDispatchType(DispatchType.GUARD);
  const onCancelDispatch = () => setDispatchType(undefined);
  const getDispatchContacts = () => {
    return dispatchType === DispatchType.PD
      ? scanSOP?.emergencyContact
      : scanSOP?.nonEmergencyContact;
  };

  const onResolve = (message: string) => {
    setUpdateType('StatusUpdate');
    const payload: UpdateEscalationRequest = {
      escalation_id: escalationId,
      resolution_comment: message,
      status: 'Resolved',
    };
    mutate(payload);
  };

  const onTalkdownCompleted = (camId: string, scanEscalationId?: string) => {
    trackEvent('talkdown_submitted_for_escalation', {
      cameraId: camId,
      escalationId: scanEscalationId,
    });
  };

  const logTwilioCall = (twilioCallSid: string, toName: string) => {
    setUpdateType('TwilioLog');
    const payload: UpdateEscalationRequest = {
      escalation_id: escalationId,
      twilio_log_type: TwilioLogType.TWILIO_CALLS,
      twilio_ssid: twilioCallSid,
      twilio_calling_to_name: toName,
      update_type: 'Call SMS logs',
    };
    mutate(payload);
  };

  return (
    <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 relative mt-2 min-h-[30rem] rounded p-4">
      <div className="absolute top-1 right-1">
        <Button variant="icon" onClick={onCancel}>
          <XMarkIcon className="h-5 w-5" />
        </Button>
      </div>
      <h2 className="text-center font-bold">
        Standard Operating Procedure (SOP)
      </h2>
      {isError && <Alert type="error">{error.message}</Alert>}
      {isLoadSOPError && <Alert type="error">{loadSOPError.message}</Alert>}
      {isSOPLoading && (
        <div className="my-4 flex h-full w-full items-center justify-center">
          <LoadingIndicator />
        </div>
      )}
      {!dispatchType && scanSOP && (
        <>
          <SOPInfo
            address={scanSOP.address}
            emergencySituations={scanSOP.emergencySituations}
            exceptions={scanSOP.exceptions}
            nonEmergencySituations={scanSOP.nonEmergencySituations}
          />
          {scanSOP.isTalkdownEnabled && (
            <div className="my-4 flex items-center justify-between rounded-md border p-2">
              <span className="text-sm">Perform talkdown:</span>
              <AudioRecorder
                cameraId={escalatedCamId}
                escalationId={escalationId}
                onTalkdown={onTalkdownCompleted}
              />
            </div>
          )}
          <div className="my-4 flex items-center justify-around">
            <Button onClick={onDispatchPD} variant="error">
              Dispatch PD
            </Button>
            <Button onClick={onDispatchGuard} variant="outline">
              Notify Security
            </Button>
          </div>
        </>
      )}
      {dispatchType && scanSOP && (
        <DispatchView
          address={scanSOP.address}
          contacts={getDispatchContacts() || []}
          onCancel={onCancelDispatch}
          onCallInitiated={logTwilioCall}
        />
      )}
      {scanSOP && (
        <div className="my-12">
          <ResolveAction onResolve={onResolve} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
}

export default ScanSOP;
