import { useEffect, useRef } from 'react';

const useMultiSSE = <T>(urls: string[], onMessage: (data: T) => void) => {
  const onMessageRef = useRef(onMessage);

  useEffect(() => {
    const eventSources = urls.map((url) => {
      const eventSource = new EventSource(url);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        onMessageRef.current(data);
      };
      return eventSource;
    });

    return () => {
      eventSources.forEach((eventSource) => eventSource.close());
    };
  }, [urls]);
};

export default useMultiSSE;
