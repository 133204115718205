import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Camera, VisionHost } from '@hakimo-ui/hakimo/types';
import { SSEDatum } from '../types/event';
import { State } from './types';
import useMultiSSE from './useMultiSSE';
import { createStateManager } from './util';

export function useCameras(
  visionHosts: VisionHost[],
  cameras: Camera[],
  onNewEvent: (cameraId: string) => void
): [State, (cameraId: string) => void, (cameraId: string) => void] {
  const stateManager = useRef(createStateManager());
  const [state, setState] = useState<State>(stateManager.current.getState());

  useEffect(() => {
    stateManager.current.onNewEvent(onNewEvent);
  }, [onNewEvent]);

  useEffect(() => {
    // Update state every second to prevent too frequent updates
    const intervalId = window.setInterval(() => {
      setState(stateManager.current.getState());
    }, 1000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  const onReceiveEvent = useCallback((datum: SSEDatum) => {
    const { id: cameraId } = datum;
    stateManager.current.receivedEvent(cameraId);
  }, []);

  const urls = useMemo(
    () => visionHosts.map((host) => `${host.host}/api/vision/events`),
    [visionHosts]
  );

  useMultiSSE(urls, onReceiveEvent);

  useEffect(() => {
    stateManager.current.updateCameras(cameras);
  }, [cameras]);

  const hideCamera = useCallback((cameraId: string) => {
    stateManager.current.hideCamera(cameraId);
    // updating state now to hide camera immediately
    setState(stateManager.current.getState());
  }, []);

  const snoozeCamera = useCallback((cameraId: string) => {
    stateManager.current.snoozeCamera(cameraId);
    // updating state now to hide camera immediately
    setState(stateManager.current.getState());
  }, []);

  return [state, hideCamera, snoozeCamera];
}
