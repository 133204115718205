export enum SOPTalkdownType {
  STATIC = 'static',
  DYANMIC = 'dynamic',
}

export type SOPTalkdown = {
  type: SOPTalkdownType;
  text: string;
};

export type SOPWorkflow = {
  firstTalkdown: SOPTalkdown;
  secondTalkdown: SOPTalkdown;
  exceptions: string[];
  siteAddress: string;
  siteGoogleMapLocation: string;
  isTalkdownEnabled: boolean;
  escalationPoints: string[];
};

export type ScanSOPContact = {
  name: string;
  phoneNumber: string;
  role?: string;
};

export type ScanTenantSOP = {
  address: string;
  emergencySituations: string[];
  nonEmergencySituations: string[];
  exceptions: string[];
  emergencyContact: ScanSOPContact[];
  nonEmergencyContact: ScanSOPContact[];
  isTalkdownEnabled?: boolean;
};

export interface SOPDTO {
  sop_text: string;
  id?: string;
  sop_workflow?: SOPWorkflow;
  scan_sop?: ScanTenantSOP;
}
