import { useLayoutEffect, useRef, useState } from 'react';
import { Defs } from './Defs';
import { Events } from './Events';
import { LoadExtraAlarms } from './LoadExtraAlarms';
import { Navigation } from './Navigation';
import { Sidebar } from './Sidebar';
import TimeRange from './TimeRange';
import { Tracks } from './Tracks';
import XLines from './XLines';
import {
  LABEL_PANEL_WIDTH,
  MARGIN,
  TIMELINE_HEIGHT,
  TIMELINE_V_SPACE,
  X_LINES_DIST,
} from './constants';
import { TimelineSource } from './types';
import { createLinearMapping, getSplitTimes } from './util';

interface Props {
  sources: TimelineSource[];
  selected: string;
  liveSourceId?: string;
  onChangeSelected: (eventId: string, sourceId: string) => void;
  onLoadNearbyAlarms?: (seconds: number) => void;
  isFetchingAlarms?: boolean;
  rawAlarmsInterval: { startTime: number; endTime: number };
  showExtraActions?: boolean;
}

export default function EventTimeline(props: Props) {
  const {
    sources,
    selected,
    liveSourceId,
    onChangeSelected,
    onLoadNearbyAlarms,
    isFetchingAlarms,
    rawAlarmsInterval: { startTime: min, endTime: max },
    showExtraActions,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(1000); // Set a default width to avoid negative lengths on first render
  const [translateX, setTranslateX] = useState(0);
  const visibleContentWidth = containerWidth - LABEL_PANEL_WIDTH;

  const height =
    sources.length * TIMELINE_HEIGHT +
    (sources.length - 1) * TIMELINE_V_SPACE +
    MARGIN.top +
    MARGIN.bottom;

  const lineIntervalSec = max - min > 1000 * 30 ? 30 : 5;
  const lineIntervalMillis = lineIntervalSec * 1000;

  const contentWidth = Math.max(
    visibleContentWidth,
    ((max - min) / lineIntervalMillis) * X_LINES_DIST
  );
  const translateXBounds = [
    -contentWidth + LABEL_PANEL_WIDTH + visibleContentWidth,
    LABEL_PANEL_WIDTH,
  ];
  const map = createLinearMapping([min, max], [0, contentWidth]);
  const xPositions = getSplitTimes(min, max, lineIntervalMillis).map(map);

  useLayoutEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    let rect = container.getBoundingClientRect();
    setContainerWidth(rect.width - MARGIN.right);
    setTranslateX(rect.width - contentWidth - MARGIN.right);

    const resizeObserver = new ResizeObserver(() => {
      rect = container.getBoundingClientRect();
      setContainerWidth(rect.width - MARGIN.right);
      setTranslateX(rect.width - contentWidth - MARGIN.right);
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, [contentWidth]);

  const onShowLeft = () => {
    setTranslateX(
      Math.min(translateX + visibleContentWidth, translateXBounds[1])
    );
  };

  const onShowRight = () => {
    setTranslateX(
      Math.max(translateX - visibleContentWidth, translateXBounds[0])
    );
  };

  return (
    <div ref={containerRef}>
      <svg width="100%" height={height}>
        <Defs visibleContentWidth={visibleContentWidth} height={height} />
        <Navigation
          height={height}
          visibleContentWidth={visibleContentWidth}
          hasLeft={translateX - LABEL_PANEL_WIDTH < 0}
          hasRight={translateX > containerWidth - contentWidth}
          onShowLeft={onShowLeft}
          onShowRight={onShowRight}
        />
        <Tracks sources={sources} width={visibleContentWidth} />
        <g className="main" clipPath="url(#main-clip)">
          <g
            className="transition-transform"
            transform={`translate(${translateX},0)`}
          >
            <TimeRange min={min} max={max} contentWidth={contentWidth} />
            <g transform={`translate(0,${MARGIN.top})`}>
              <Events
                sources={sources}
                selected={selected}
                map={map}
                onChangeSelected={onChangeSelected}
              />
            </g>
            <XLines
              positions={xPositions}
              height={height}
              interval={lineIntervalSec}
            />
          </g>
        </g>
        {showExtraActions && (
          <g className="load-nearby-alarms">
            <LoadExtraAlarms
              onLoadNearbyAlarms={onLoadNearbyAlarms}
              isFetchingAlarms={isFetchingAlarms}
              containerWidth={containerWidth}
            />
          </g>
        )}
        <Sidebar sources={sources} liveSourceId={liveSourceId} />
      </svg>
    </div>
  );
}
