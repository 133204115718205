import { NavItem } from '@hakimo-ui/hakimo/ui-navigation';
import {
  ArrowsPointingInIcon,
  BellAlertIcon,
  BoltIcon,
  BuildingOfficeIcon,
  ChartPieIcon,
  ClipboardDocumentListIcon,
  EyeIcon,
  GlobeAltIcon,
  SpeakerWaveIcon,
  UsersIcon,
  VideoCameraIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/outline';
import { DoorGroupIcon } from './icons';

export const navItems: NavItem[] = [
  {
    id: 'monitoring',
    name: 'Monitoring',
    href: '/monitoring',
    icon: EyeIcon,
    requiredPermissions: [
      'location_alarms/monitoring:count',
      'location_alarms/monitoring:get_pending',
    ],
  },
  {
    id: 'alarms',
    name: 'Alarms',
    href: '/alarms',
    icon: BellAlertIcon,
    requiredPermissions: ['alarm:view'],
  },
  {
    id: 'location-alarms',
    name: 'Location Alarms',
    href: '/location-alarms',
    icon: ArrowsPointingInIcon,
    requiredPermissions: ['location_alarm:view'],
  },
  {
    id: 'doors',
    name: 'Doors',
    href: '/doors',
    icon: BuildingOfficeIcon,
    requiredPermissions: ['door:view'],
  },
  {
    id: 'cameras',
    name: 'Cameras',
    href: '/cameras',
    icon: VideoCameraIcon,
    requiredPermissions: ['camera:view'],
  },
  {
    id: 'audiodevices',
    name: 'Audio Devices',
    href: '/audiodevices',
    icon: SpeakerWaveIcon,
    requiredPermissions: ['audio_devices:view'],
  },
  {
    id: 'locations',
    name: 'Locations',
    href: '/locations',
    icon: GlobeAltIcon,
    requiredPermissions: ['location:view'],
  },
  {
    id: 'door_groups',
    name: 'Door Groups',
    href: '/doorgroups',
    icon: DoorGroupIcon,
    requiredPermissions: ['door_group:view'],
  },
  {
    id: 'users',
    name: 'Users',
    href: '/users',
    icon: UsersIcon,
    requiredPermissions: ['user:view'],
  },
  {
    id: 'auditLog',
    name: 'Audit Log',
    href: '/audit-log',
    icon: ClipboardDocumentListIcon,
    requiredPermissions: ['audit-log:view'],
  },
  {
    id: 'insights',
    name: 'Insights',
    href: '/insights',
    icon: ChartPieIcon,
    requiredPermissions: ['insights:view'],
  },
  {
    id: 'scan',
    name: 'Scan',
    href: '/scan',
    icon: ViewfinderCircleIcon,
    requiredPermissions: [],
  },
  {
    id: 'scan',
    name: 'Scan Escalations',
    href: '/scan-escalations',
    icon: BoltIcon,
    requiredPermissions: [],
  },
];
